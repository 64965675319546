import * as React from "react";
import { observer } from "mobx-react";
import { LOBState, CarWizardState, HotelWizardState, LxWizardState, PackageWizardState } from "src/stores/wizard/state";
import { UitkTextInput } from "@egds/react-core/input";
import { LocationType } from "./LocationType";
import { Result, listResult } from "./LocationList";
import { PackagesSubLobs } from "src/stores/wizard/state/typings";
import { useLocalization } from "@shared-ui/localization-context";

export interface DefaultLocationProps {
  lobState: LOBState;
  defaultLocation: string;
  locationConfig: any;
  locationType: LocationType;
  name: string;
  biasedStopover?: string;
}

const noop = () => {
  return null;
};

export const DefaultLocation = observer(
  ({ lobState, defaultLocation, locationType, locationConfig, name, biasedStopover }: DefaultLocationProps) => {
    const { formatText } = useLocalization();
    const locationState = lobState.location;
    const { DESTINATION } = LocationType;

    const defaultLocationArray = defaultLocation.split(":");
    const setDefaultLocationAndGetValue = () => {
      if (biasedStopover) {
        return biasedStopover;
      }
      locationState[locationType].value = defaultLocationArray[0];

      return locationState[locationType].value;
    };

    const value = setDefaultLocationAndGetValue();

    //label selection
    const isCarWizardState = lobState instanceof CarWizardState;
    const destinationValue =
      locationState.destination.value === locationState.origin.value && isCarWizardState
        ? ""
        : locationState.destination.value;

    const getDestinationLabelToken = () => {
      const { withValueLabelToken, noValueLabelToken, labelToken } = locationConfig.destination;

      return lobState instanceof CarWizardState && lobState.isDesktop
        ? destinationValue
          ? withValueLabelToken
          : noValueLabelToken
        : labelToken;
    };

    const locationLabelToken =
      locationType === DESTINATION ? getDestinationLabelToken() : locationConfig.origin.labelToken;

    const { multiHotelIDs } = lobState.config;
    const isHotelInstance = lobState instanceof HotelWizardState;
    const isHotelCarInstance = isHotelInstance && (lobState as HotelWizardState)?.isAddACarChecked === true;
    const isHotelFlightInstance = isHotelInstance && (lobState as HotelWizardState)?.isAddAFlightChecked === true;
    const isPackageInstance =
      lobState.subLOBState === PackagesSubLobs.HOTEL_CAR ||
      lobState.subLOBState === PackagesSubLobs.FLIGHT_HOTEL ||
      lobState.subLOBState === PackagesSubLobs.FLIGHT_HOTEL_CAR ||
      lobState instanceof PackageWizardState;
    const isHotelPackageInstance = isPackageInstance || isHotelInstance;
    const hasMultiHotelIds = isHotelPackageInstance && multiHotelIDs;
    const hotelIdResult: Result[] = hasMultiHotelIds ? listResult(multiHotelIDs, "|") : [];
    const groupValue = hotelIdResult.find(({ key }) =>
      key.toLowerCase().includes(locationState.destination.value.toLowerCase())
    );
    const isLXInstance = lobState instanceof LxWizardState;
    const inputName = locationType == DESTINATION ? "regionId" : "originId";

    return (
      <div>
        <UitkTextInput
          data-testid="default-location-input"
          label={formatText(locationLabelToken)}
          name={isLXInstance ? "location" : name}
          value={value}
          onChange={noop}
        />
        <input
          type="hidden"
          data-testid="default-location-hidden-input"
          name={isLXInstance ? "rid" : inputName}
          value={defaultLocationArray && defaultLocationArray[1]}
        />
        {hasMultiHotelIds && (
          <input
            type="hidden"
            name={isPackageInstance || isHotelCarInstance || isHotelFlightInstance ? "hotelIds" : "group"}
            value={groupValue?.value}
          />
        )}
      </div>
    );
  }
);
