import * as React from "react";
import { UitkSelect } from "@egds/react-core/input";
import {
  HotelWizardState,
  LOBState,
  LxWizardState,
  CarWizardState,
  FlightWizardState,
  FlightType,
  PackageWizardState,
} from "src/stores/wizard/state";
import { observer } from "mobx-react-lite";
import TrackingUtils, { ACTION_DONE } from "src/utils/TrackingUtils";
import { LocationType } from "./LocationType";
import { PackagesSubLobs } from "src/stores/wizard/state/typings";
import { useLocalization } from "@shared-ui/localization-context";

export interface LocationListProps {
  lobState: LOBState;
  tracker: TrackingUtils;
  locationList: string[];
  locationType: LocationType;
  locationConfig: any;
  name: string;
  biasedStopover?: string[];
  onBiasedSelectChange?: (selectEvent: React.ChangeEvent<HTMLSelectElement>) => void;
  multiLegIndex?: number;
}

export interface Result {
  key: string;
  value: string;
}

export const listResult = (locationArray: string[], splitVal: string) => {
  const locationListToRender = locationArray.map((val) => {
    const [key, value] = val.split(splitVal);

    return { key, value };
  });

  return locationListToRender;
};

export const LocationList = observer(
  ({
    lobState,
    tracker,
    locationList,
    locationType,
    locationConfig,
    name,
    biasedStopover,
    onBiasedSelectChange,
  }: LocationListProps) => {
    const { formatText } = useLocalization();
    const locationState = lobState.location;
    const { DESTINATION } = LocationType;
    const regionLabel = locationType == DESTINATION ? "regionId" : "originId";

    const defaultLocationList = listResult(locationList, ":");
    const selectedValue = defaultLocationList.find(({ key }) => key === locationState[locationType].value);

    const getDefaultValue = () => {
      if (biasedStopover) {
        if (lobState instanceof FlightWizardState && lobState.listOfAdditionalLegs.length > 0) {
          return lobState.listOfAdditionalLegs[0].location.origin.value;
        }
        return biasedStopover[0];
      }
      return defaultLocationList[0].key;
    };

    //setting inital value on wizard render otherwise it is giving error on the page load
    const value = getDefaultValue();

    if (!locationState[locationType].value && lobState.subLOBState !== FlightType.MULTI_CITY) {
      locationState[locationType].value = value;
    }

    const onSelectChange = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
      if (onBiasedSelectChange) {
        onBiasedSelectChange(selectEvent);
      } else {
        locationState[locationType].value = selectEvent.currentTarget.value;
        tracker.trackEvent(locationType + ACTION_DONE);
      }
    };

    //label selection
    const isCarWizardState = lobState instanceof CarWizardState;
    const destinationValue =
      locationState.destination.value === locationState.origin.value && isCarWizardState
        ? ""
        : locationState.destination.value;

    const getDestinationLabelToken = () => {
      const { withValueLabelToken, noValueLabelToken, labelToken } = locationConfig.destination;

      return lobState instanceof CarWizardState && lobState.isDesktop
        ? destinationValue
          ? withValueLabelToken
          : noValueLabelToken
        : labelToken;
    };
    const locationLabelToken =
      locationType === DESTINATION ? getDestinationLabelToken() : locationConfig.origin.labelToken;

    //hotel id/s for destination
    const { multiHotelIDs } = lobState.config;
    const isHotelInstance = lobState instanceof HotelWizardState;
    const isHotelCarInstance = isHotelInstance && (lobState as HotelWizardState)?.isAddACarChecked === true;
    const isHotelFlightInstance = isHotelInstance && (lobState as HotelWizardState)?.isAddAFlightChecked === true;
    const isPckgInstance =
      lobState.subLOBState === PackagesSubLobs.HOTEL_CAR ||
      lobState.subLOBState === PackagesSubLobs.FLIGHT_HOTEL ||
      lobState.subLOBState === PackagesSubLobs.FLIGHT_HOTEL_CAR ||
      lobState instanceof PackageWizardState;
    const isHotelPckgInstance = isPckgInstance || isHotelInstance;
    const hasMultiHotelIds = isHotelPckgInstance && multiHotelIDs;
    const hotelIdResult: Result[] = hasMultiHotelIds ? listResult(multiHotelIDs, "|") : [];
    const groupValue = hotelIdResult.find(({ key }) =>
      key.toLowerCase().includes(locationState.destination.value.toLowerCase())
    );
    const isLXInstance = lobState instanceof LxWizardState;

    return (
      <div>
        <UitkSelect
          id="location-list-select"
          data-testid="location-list-select"
          name={isLXInstance ? "location" : name}
          label={formatText(locationLabelToken)}
          aria-label={locationType}
          onChange={onSelectChange}
          value={selectedValue ? selectedValue.key : value}
        >
          {defaultLocationList.map((location) => (
            <option key={location.key} value={location.key}>
              {location.key}
            </option>
          ))}
        </UitkSelect>
        <input
          type="hidden"
          data-testid="location-list-hidden-input"
          name={isLXInstance ? "rid" : regionLabel}
          value={selectedValue?.value}
        />
        {hasMultiHotelIds && (
          <input
            type="hidden"
            name={isPckgInstance || isHotelCarInstance || isHotelFlightInstance ? "hotelIds" : "group"}
            value={groupValue?.value}
          />
        )}
      </div>
    );
  }
);
